import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SecureLogos } from "../Components";
import Assets from "../../assets/Assets";
import { ReactComponent as AppleIcon } from "../../assets/img/logos/apple-icon.svg";

import "./styles.scss";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

export const ApplePay = ({ clearState }) => {
  const { data, id } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [continueUrl, setContinueUrl] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [label, setLabel] = useState(null);
  const [opertionId, setOpertionId] = useState(null);
  const [merchantValidationUrl, setMerchantValidationUrl] = useState(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (data.applePayOrGooglePayRequestData) {
      const payData = JSON.parse(data.applePayOrGooglePayRequestData);

      const paymentReq = {
        countryCode: payData.applePayPaymentRequest.countryCode,
        currencyCode: payData.applePayPaymentRequest.currencyCode,
        merchantName: payData.applePayPaymentRequest.merchantInfo.merchantId,
        supportedNetworks: payData.applePayPaymentRequest.supportedNetworks,
        merchantCapabilities:
          payData.applePayPaymentRequest.merchantCapabilities,
        total: {
          label: payData.applePayPaymentRequest.merchantInfo.label,
          amount: payData.applePayPaymentRequest.amount,
        },
      };

      setMerchantId(payData.applePayPaymentRequest.merchantInfo.merchantId);
      setLabel(payData.applePayPaymentRequest.merchantInfo.label);
      setOpertionId(payData.applePayPaymentRequest.id);
      setMerchantValidationUrl(
        payData.applePayPaymentRequest.merchantValidationUrl
      );
      setPaymentRequest(paymentReq);
      setContinueUrl(payData.continueUrl);
      setCheckoutUrl(payData.checkoutUrl);
    }
  }, [data]);

  useEffect(() => {
    if (window.ApplePaySession && merchantId) {

      const promise = window.ApplePaySession.applePayCapabilities(merchantId);

      promise
      .then((capabilities) => {
        console.log("capabilities");
        console.log(capabilities)
        switch (capabilities.paymentCredentialStatus) {
          case "paymentCredentialsAvailable":
          case "paymentCredentialStatusUnknown":
          case "paymentCredentialsUnavailable":
            const applePayButton = document.getElementById("apple-pay-button");
            applePayButton.style.display = "inline-block";
            break;
          case "applePayUnsupported":
            console.log("applePayUnsupported");
            break;
        }
      })
      .catch((error) => {
        sendData(error);
      });
    }

    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener("click", handlePayment);
      }
    };
  }, [merchantId]);

  const handlePayment = () => {
    if (!paymentRequest) {
      console.log("Payment request is not set");
      return;
    }

    try {
      const session = new window.ApplePaySession(3, paymentRequest);
      session.begin();

      session.onvalidatemerchant = (event) => {
        validateMerchantUrl(event.validationURL).then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        });
      };

      session.onpaymentauthorized = (event) => {
        if (event.payment.token) {
          const tokenOptions = {
            isSuccess: true,
            error: null,
            token: event.payment.token,
          };

          fetch(continueUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(tokenOptions),
          })
            .then((response) => {
              window.location.href = checkoutUrl;
            })
            .catch((error) => {
              window.location.href = checkoutUrl;
            });

          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
        } else {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        }
      };

      session.oncancel = (event) => {
        session.abort();
        cancel();
      };
    } catch (error) {
      sendData(error);
    }
  };

  const validateMerchantUrl = (validationURL) => {
    return new Promise((resolve, reject) => {
      const operation = {
        url: validationURL,
        merchantId: merchantId,
        label: label,
        id: opertionId,
      };

      fetch(merchantValidationUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(operation),
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          sendData(error);
          reject();
        });
    });
  };

  const sendData = (error) => {
    const tokenOptions = {
      isSuccess: false,
      error: error,
      token: null,
    };

    fetch(continueUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tokenOptions),
    })
      .then((response) => {
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.log(error);
        window.location.href = checkoutUrl;
      });
  };

  const cancel = () => {
    clearState(false);
    cancelPayment();
  };

  return (
    <>
      {paymentRequest && (
        <>
          <div className="wrapper-state wrapper-pay">
            <div className="navbar">
              <img
                className="inner-form-logo"
                src={Assets["payadmit"]}
                alt="Payadmit"
                style={{ marginTop: "0px" }}
              />
              {data.showCancelButton && (
                <div className="button-cancel" onClick={() => cancel()}>
                  <img src={Assets.close} alt="cancel" />
                </div>
              )}
            </div>
            <div className="state-pay state-pay__info">
              <p className="state-pay__title">Order Id:</p>
              <p className="state-pay__text">{id}</p>
            </div>
            <div className="state-pay state-pay__info">
              <p className="state-pay__title">{t("AmountLabel")}:</p>
              <div className="state-pay__info-item">
                <p className="state-pay__text">{paymentRequest.currencyCode}</p>
                <p className="state-pay__text">{paymentRequest.total.amount}</p>
              </div>
            </div>

            <div className="state-pay__button">
              <button
                ref={buttonRef}
                id="apple-pay-button"
                style={{ display: "none" }}
                onClick={handlePayment}
              >
                <span>
                  Pay with <AppleIcon /> Pay
                </span>
              </button>
            </div>
          </div>
          <SecureLogos />
        </>
      )}
    </>
  );
};
