import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./style.scss";
import Assets from "../../../assets/Assets";

export const SaveCardCheckbox = (props) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="wrapper-input wrapper-checkbox save-card">
      <input
        ref={register()}
        id={props.id}
        name={props.name}
        type="checkbox"
        defaultChecked={true}
        {...props.additionalAttributes}
      />
      <div className="save-card__label-container">
        <img className="save-card__img" src={Assets.save_card_icon} alt="" />
        <label htmlFor={props.id}>{t(props.label)}</label>
      </div>
    </div>
  );
};
